import React from 'react'
import Header from '../Components/Header'
import AddP from '../Components/Add'
import Footer from '../Components/Footer'

function AddPost() {
  return (
    <div>
        <Header/>
        <AddP/>
        <Footer/>
    </div>
  )
}

export default AddPost