/* eslint-disable */
import React, { useEffect, useState ,useRef} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Baseurl } from "./Baseurl";
import "swiper/css";
import 'swiper/css/navigation';

import { Navigation } from 'swiper/modules';
import axios from "axios";
import { flushSync } from "react-dom";

function HeroSection() {
  const [data, setData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [time, setTime] = useState(0);
  const [playing, setPlaying] = useState(true);
  console.log(activeIndex);
  const vidRef = useRef(null);
  const getAllPost = async () => {
    try {
      const response = await axios.get(`${Baseurl}/api/video`);
      setData(response.data.videos);
      vidRef.current.swiper.update();
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllPost();
  }, []);

  // const handleLoadedMetadata = (event) => {
  //   const durationInSeconds = event.target.duration;
  //   const durationInMilliseconds = durationInSeconds * 1000;
  //   setTime(durationInMilliseconds)
  //   // console.log("Video duration:", durationInMilliseconds, "milliseconds");
  //   // You can store or process the duration here
  // };
 
  // console.log(vidRef.current);

  // const handleSlideChange = () => {
  //   const swiper = vidRef.current.swiper;
  //   const activeIndex = swiper.realIndex; // Get the index of the current active slide
  //   const videoElement = document.getElementById(`video_${activeIndex}`);
  //   if (videoElement) {
  //     videoElement.play(); // Play the video on the current slide
  //   }
  // };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 px-0">
          <Swiper ref={vidRef}
            navigation={true}
            // onSlideChange={handleSlideChange} 
             modules={[Navigation]} className="mySwiper" 
            // onRealIndexChange={(element)=>setActiveIndex(element.activeIndex)}
               style={{ height: "500px" }} 
           
          >
         
              {data?.map((item,index) => (
                <SwiperSlide key={item.id} >
                  <video
                  // id={`video_${index}`} 
                  // ref={vidRef}
                    width="100%"
                    height="100%" 
                    autoPlay
                    controls
                    // autoPlay={index === activeIndex && playing}
                    // loops
                    style={{ objectFit: "cover" }}
                    src={`${Baseurl}${item?.name}`}
                    // onClick={()=>handleSwiperSlideClick(index)}
                    // onLoadedMetadata={handleLoadedMetadata}
/>
                  
                </SwiperSlide>
              ))}
               
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
