import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Baseurl } from "./Baseurl";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";

function AddP() {
  // const userName = "John Doe";
  // const userImageUrl = "https://images.unsplash.com/photo-1575936123452-b67c3203c357?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8fDA%3D";

  const validationSchema = Yup.object().shape({
    userName: Yup.string().required("User Name is required"),
    postTitle: Yup.string().required("Post Title is required"),
    postDescription: Yup.string().required("Post Description is required"),
    // uploadImage: Yup.mixed().required("Upload Your Image is required"),
    // postImage: Yup.mixed().required("Upload Post Image is required"),
  });
  const Navigate = useNavigate();
  // const data1 = localStorage.getItem("userData");
  // const parsedData1 = JSON.parse(data1);

  // console.log(parsedData1.files[0].name);
  const formik = useFormik({
    initialValues: {
      userName: "",
      postTitle: "",
      postDescription: "",
      // uploadImage: null,
      // postImage: null,
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      // console.log(values, "val");

      resetForm();
      try {
        const formData = new FormData();
        formData.append("username", values.userName);
        formData.append("title", values.postTitle);
        formData.append("description", values.postDescription);
        // formData.append("user_image", values.uploadImage);
        if(values.postImage===undefined){

          formData.append("post_files", '');
        }
        else{

          formData.append("post_files[0]", values.postImage);
        }

        await axios
          .post(`${Baseurl}/api/post`, formData)
          .then((response) => {
            setSubmitting(false);
            console.log(response.data, "post added---->");
            localStorage.setItem(
              "userData",
              JSON.stringify(response.data?.post)
            );
            // toast.success("Post Add");
            Swal.fire({
              icon: "success",
              title: "Post Added Successfully",
            }).then((result) => {
              if (result.isConfirmed) {
                Navigate("/");
              }
            });
          })
          .catch((error) => {
            setSubmitting(false);
            console.error("Error adding post:", error);
            toast.error(error);
          });
      } catch (error) {
        setSubmitting(false);
        console.error("Error adding post:", error);
        // Handle error
      }
    },
  });

  return (
    <div className="comment-respond container">
      <span
        style={{
          position: "relative",
          left: "0%",
          fontSize: "20px",
          cursor: "pointer",
        }}
        onClick={() => Navigate("/")}
      >
        <IoMdArrowRoundBack className="mb-1 mr-2" />
        Go BAck
      </span>
      <div className="user-info-section d-flex justify-content-center">
        <div className="user-info">
          {/* {console.log(Baseurl + data1.data?.)} */}
          {/* <img src={Baseurl + parsedData1.files[0].name} alt="User" className="img-w" /> */}
          {/* <p className="text-center"> {parsedData1?.username}</p> */}
        </div>
      </div>

      {/* Post Information Section */}
      <div className="post-info-section mt-5">
        <h4 className="title" style={{ fontSize: "25px" }}>
          Add Post
        </h4>
        <form onSubmit={formik.handleSubmit}>
          <p className="comment-notes">
            Required fields are marked <span className="required">*</span>
          </p>
          <div className="row row--10">
            <div className="col-lg-6 col-md-4 col-12">
              <div className="form-group">
                <label>User Name*</label>
                <input
                  id="userName"
                  name="userName"
                  type="text"
                  value={formik.values.userName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.userName && formik.errors.userName ? (
                  <div className="error">{formik.errors.userName}</div>
                ) : null}
              </div>
            </div>
            <div className="col-lg-6 col-md-4 col-12">
              <div className="form-group">
                <label>Post Title*</label>
                <input
                  id="postTitle"
                  name="postTitle"
                  type="text"
                  value={formik.values.postTitle}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.postTitle && formik.errors.postTitle ? (
                  <div className="error">{formik.errors.postTitle}</div>
                ) : null}
              </div>
            </div>
            <div className="col-lg-12 col-md-4 col-12">
              <div className="form-group">
                <label>Post Description*</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={formik.values.postDescription}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    formik.setFieldValue("postDescription", data);
                  }}
                />
                {formik.touched.postDescription &&
                formik.errors.postDescription ? (
                  <div className="error">{formik.errors.postDescription}</div>
                ) : null}
              </div>
            </div>
            {/* <div className="col-lg-6">
              <div className="form-group">
                <label>Upload Your Image*</label>
                <input
                  type="file"
                  id="uploadImage"
                  name="uploadImage"
                  style={{ paddingTop: "9px" }}
                  onChange={(event) =>
                    formik.setFieldValue("uploadImage", event.currentTarget.files[0])
                  }
                />
                {formik.touched.uploadImage && formik.errors.uploadImage ? (
                  <div className="error">{formik.errors.uploadImage}</div>
                ) : null}
              </div>
            </div> */}
            <div className="col-lg-6">
              <div className="form-group">
                <label>Upload Post Image</label>
                <input
                  type="file"
                  id="postImage"
                  name="postImage"
                  style={{ paddingTop: "9px" }}
                  onChange={(event) =>
                    formik.setFieldValue(
                      "postImage",
                      event.currentTarget.files[0]
                    )
                  }
                />
                
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-submit cerchio">
                <input
                  type="submit"
                  id="submit"
                  className="axil-button button-rounded"
                  value="Post"
                  disabled={formik.isSubmitting}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <ToastContainer className="custom-toast" />
    </div>
  );
}

export default AddP;
