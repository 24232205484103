import React, { useState } from "react";
import { RiCheckLine, RiCloseLine } from "react-icons/ri";

function Share() {
  const [embedUrl, setEmbedUrl] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  const handleInputChange = (event) => {
    setEmbedUrl(event.target.value);
  };

  const handleButtonToggle = () => {
    setShowInput((prevState) => !prevState); // Toggle input visibility
    setShowVideo(false); // Hide video when input is displayed
    setEmbedUrl(""); // Clear input value when input is displayed
  };

  const handleYesClick = () => {
    const inputUrl = embedUrl.trim();
    const videoId = inputUrl.split("/").pop();
    const fullEmbedUrl = inputUrl
      ? `https://www.youtube.com/embed/${videoId}`
      : "";
    setEmbedUrl(fullEmbedUrl);
    setShowVideo(true);
    setShowInput(false); // Hide input when "Yes" is clicked
  };

  return (
    <div className="form-group">
      <div className="form-submit cerchio">
        <button
          onClick={handleButtonToggle}
          className="axil-button button-rounded"
        >
          {showInput ? "Close Input" : "Open Input"}
        </button>
      </div>
      {showInput && (
        <input
         data-aos="flip-left"
          type="text"
          className="col-6"
          placeholder="Paste Video URL here"
          value={embedUrl}
          onChange={handleInputChange}
        />
      )}
      {showVideo && embedUrl && (
        <iframe

          width="560"
          height="315"
          src={embedUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      )}
      {showInput && embedUrl && (
        <div className="form-submit cerchio">
          <button
            onClick={handleYesClick}
            className="axil-button button-rounded"
          >
            <RiCheckLine /> Yes
          </button>
          <button
            onClick={() => setShowInput(false)}
            className="axil-button button-rounded"
          >
            <RiCloseLine /> No
          </button>
        </div>
      )}
    </div>
  );
}

export default Share;
