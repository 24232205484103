/* eslint-disable */

// import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Header from './Components/Header';
import Home from './Pages/Home';
import Details from './Pages/Details';
import NotFound from './Pages/404-NotFound';
import { useState, useEffect } from 'react';
import AddPost from './Pages/AddPost';
import AOS from 'aos';
// import '@ckeditor/ckeditor5-build-classic/build/translations/en.css';

import 'aos/dist/aos.css'; // Import AOS CSS file
// import ShareButtons from './Components/Share';
import Share from './Components/Share';
import { Suspense } from 'react';
// import MyEditor from './Components/Drafth';



// import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  // const [theme, setTheme] = useState('light');
  useEffect(() => {
    AOS.init();
  },[])

  // const handleThemeChange = (newTheme) => {
  //   setTheme(newTheme);
  //   if(theme==="dark"){
  //       window.document.body.classList.remove('active-dark-mode');
  //       window.document.body.classList.add('active-light-mode');
  //   }
  //   else{
  //     window.document.body.classList.remove('active-light-mode');
  //     window.document.body.classList.add('active-dark-mode');
  //   }

  // };



  return (
    <div>
      {/* <div id="my_switcher" className="my_switcher">
        <ul>
          <li>
            <a href="javascript: void(0);" onClick={() => handleThemeChange('light')} className={theme === 'light' ? 'setColor light active' : 'setColor dark'}>
              <span title="Light Mode">Light</span>
            </a>
          </li>
          <li>
            <a href="javascript: void(0);" onClick={() => handleThemeChange('dark')} className={theme === 'dark' ? 'setColor dark active' : 'setColor'}>
              <span title="Dark Mode">Dark</span>
            </a>
          </li>
        </ul>
      </div> */}
      

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Suspense fallback={"faiz"}>
          <Home /> 
          </Suspense>} />
          {/* <Route path="/details" element={<Details />} /> */}
          <Route path="*" element={<NotFound />} />
          <Route path="/add-post" element={<AddPost />} />
          <Route path="/post-detail/:id" element={<Details />} />
          <Route path="/practice" element={<Share />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
