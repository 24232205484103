/* eslint-disable */
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Baseurl } from './Baseurl';

function Header() {
  const [data, setData] = useState([]);
  const getAllPost = async () => {
    try {
      const response = await axios.get(
        `${Baseurl}/api/videotitle/get`
      );
      setData(response.data.video_title);
      // console.log(response.data.videos,"Faiz1");
      
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };
  
  useEffect(()=>{
    window.scrollTo(0,0)
    getAllPost()
    
  },[])
  useEffect(()=>{
    window.scrollTo(0,0)

  },[])
    localStorage.setItem("body-color", true)
    // const [, setShow] = useState(false);
    const [header,setheader]= useState(false)
    // const location = useLocation();
    // const { pathname } = location;

    // const handleClose = () => setShow(false);
    // const handleShow = () => {
    //     setShow(true);
    // }
    // const scroll = () => {
    //   const section = document.querySelector("#same");
    //   console.log(section);
    //   section.scrollIntoView({ behavior: "smooth", block: "start" });
    // };
  return (
    <>
    <section className={ header === true ? `popup-mobile-menu-show` :''}>

     <div class="main-wrapper">
        <div class="mouse-cursor cursor-outer" style={{visibility:"visible"}}></div>
        <div class="mouse-cursor cursor-inner" style={{visibility:"visible"}}></div>

        <header className="header axil-header  header-light header-sticky " style={{background:"white"}}>
  <div className="header-wrap">
    <div className="row justify-content-between align-items-center">
      <div className="col-xl-3 col-lg-3 col-md-4 col-sm-3 col-12">
        <div className="logo">
          <Link to={'/'}>
            <img
              className="dark-logo"
              src="/assets/images/logo/logo1.jpg"
              alt="Blogar logo"
            />
            <img
              className="light-logo"
              src="/assets/images/logo/logo1.jpg"
              alt="Blogar light logo"
            />
          </Link>
        </div>
      </div>
      <div className="col-xl-6 d-none d-xl-block">
        <div className="mainmenu-wrapper">
          <nav className="mainmenu-nav">
            <ul className="mainmenu">
              <li className="menu-item-has-children">
                {/* <Link to={"/"}>Home</Link> */}
               
              </li>
              <li className="menu-item-has-children">
                {/* <Link to={"/?id=home"}>
                  View Post
                  </Link> */}
               
              </li>
              
              <li className="menu-item-has-children">
                <a href={data?.header_link} target='_blank'>{data?.header_title}</a>
               {/*  */}
              </li>
         
            </ul>
          </nav>
        </div>
      </div>
      <div className="col-xl-3 col-lg-8 col-md-8 col-sm-9 col-12">
        <div className="header-search text-end d-flex align-items-center">
     
          <div className="mobile-search-wrapper d-sm-none d-block">
            
            <form className="header-search-form">
              <div className="axil-search form-group">
                <button type="submit" className="search-button">
                  <i className="fal fa-search" />
                </button>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                />
              </div>
            </form>
          </div>
          <ul className="metabar-block">
            {/* <li className="icon">
              <a href="f" onClick={(e)=>e.preventDefault()} alt="data">
                <i className="fas fa-bookmark" />
              </a>
            </li> */}
            {/* <li className="icon">
              <a href="f" onClick={(e)=>e.preventDefault()} alt="data">
                <i className="fas fa-bell" />
              </a>
            </li> */}
{/* <<<<<<< HEAD */}
{/* // <<<<<<< HEAD */}
            {/* <li>
=======
=======
>>>>>>> e273e2f0671228b49ee4a86798e9de24fc2228af
            <li>
>>>>>>> e273e2f0671228b49ee4a86798e9de24fc2228af
              <a href="f" onClick={(e)=>e.preventDefault()} alt="data">
                <img
                  src="/assets/images/others/author.png"
                  alt="Author Images"
                />
              </a>
            </li> */}
          </ul>
          <div className="hamburger-menu d-block d-xl-none">
            <div className="hamburger-inner">
              <div className="icon">
                <i className="fal fa-bars"  onClick={()=>setheader(true)}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<div className="popup-mobilemenu-area">
  <div className="inner">
    <div className="mobile-menu-top">
      <div className="logo">
        <a href="index.html" style={{width: "61px",height:"50px"}}>
          <img
          style={  {  width: "61px",height:"66px"}}
            className="dark-logo"
            src="/assets/images/logo/logo1.jpg"

            alt="Logo Images"
          />
          <img
          style={  {  width: "61px",height:"66px"}}
            className="light-logo"
            src="/assets/images/logo/logo1.jpg"

            alt="Logo Images"
          />
        </a>
      </div>
      <div className="mobile-close"  onClick={()=>setheader(false)}>
        <div className="icon">
          <i className="fal fa-times" />
        </div>
      </div>
    </div>
    {/* <ul className="mainmenu">
      <li>
        <Link to={"/"}>Home</Link>
     
      </li>
      
      <li >
        <Link to={"/add-post"}>Add Post</Link>
      
      </li>
      <li>
        <Link to={"/details"}>View Post</Link>
      </li>
     
    </ul>
   */}
  </div>
</div>
</div>
</section>


</>


  )
}

export default Header