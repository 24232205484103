import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <>
  <div className="axil-footer-area axil-footer-style-1 footer-variation-2 mt-3">
   
    {/* Start Footer Top Area  */}
    <div className="footer-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-4">
            <div className="logo">
              <Link to={'/'}>
                <img
                style={{height:"200px"}}
                  className="dark-logo"
                  src="/assets/images/logo/new2.png"
                  alt="Logo Images"
                />
                <img
                  className="white-logo"
                  src="/assets/images/logo/new1.png"
                  alt="Logo Images"
                />
              </Link>
            </div>
          </div>
          <div className="col-lg-8 col-md-8">
            {/* Start Post List  */}
            <div className="d-flex justify-content-start mt_sm--15 justify-content-md-end align-items-center flex-wrap">
              <h5 className="follow-title mb--0 mr--20">Follow Us</h5>
              <ul className="social-icon color-tertiary md-size justify-content-start">
                <li>
                  <a href="f" onClick={(e)=>e.preventDefault()} alt="data">
                    <i className="fab fa-facebook-f" />
                  </a>
                </li>
                <li>
                  <a href="f" onClick={(e)=>e.preventDefault()} alt="data">
                    <i className="fab fa-instagram" />
                  </a>
                </li>
                <li>
                  <a href="f" onClick={(e)=>e.preventDefault()} alt="data">
                    <i className="fab fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="f" onClick={(e)=>e.preventDefault()} alt="data">
                    <i className="fab fa-linkedin-in" />
                  </a>
                </li>
              </ul>
            </div>
            {/* End Post List  */}
          </div>
        </div>
      </div>
    </div>
    {/* End Footer Top Area  */}
    {/* Start Copyright Area  */}
    <div className="copyright-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-9 col-md-8">
            <div className="copyright-left">
              <ul className="mainmenu justify-content-start">
                <li>
                  <a className="hover-flip-item-wrapper" href="f" onClick={(e)=>e.preventDefault()} alt="data">
                    <span className="hover-flip-item">
                      <span data-text="Contact Us">Contact Us</span>
                    </span>
                  </a>
                </li>
                <li>
                  <a className="hover-flip-item-wrapper" href="f" onClick={(e)=>e.preventDefault()} alt="data">
                    <span className="hover-flip-item">
                      <span data-text="Terms of Use">Terms of Use</span>
                    </span>
                  </a>
                </li>
                <li>
                  <a className="hover-flip-item-wrapper" href="f" onClick={(e)=>e.preventDefault()} alt="data">
                    <span className="hover-flip-item">
                      <span data-text="AdChoices">AdChoices</span>
                    </span>
                  </a>
                </li>
                <li>
                  <a className="hover-flip-item-wrapper" href="f" onClick={(e)=>e.preventDefault()} alt="data">
                    <span className="hover-flip-item">
                      <span data-text="Advertise with Us">
                        Advertise with Us
                      </span>
                    </span>
                  </a>
                </li>
                <li>
                  <a className="hover-flip-item-wrapper" href="f" onClick={(e)=>e.preventDefault()} alt="data">
                    <span className="hover-flip-item">
                      <span data-text="Blogar Store">Blogar Store</span>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-4">
            <div className="copyright-right text-start text-md-end mt_sm--20">
              <p className="b3">All Rights Reserved © 2023</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* End Copyright Area  */}
  </div>
  {/* End Footer Area  */}
  {/* Start Back To Top  */}
  <a id="backto-top"  href='f'>
    </a>
  {/* End Back To Top  */}
</>

  )
}

export default Footer