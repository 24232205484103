import React from 'react'
import Header from '../Components/Header'
import Eroor from '../Components/Eroor'
import Footer from '../Components/Footer'

function NotFound() {
  return (
    <div>
        <Header />
        <Eroor />
        <Footer/>
    </div>
  )
}

export default NotFound