import React from 'react'
import { Link } from 'react-router-dom'

function Eroor() {
  return (
    <div className="error-area bg_image--4 bg-color-grey">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="inner">
            <img src="assets/images/others/404.png" alt="Error Images" />
            <h1 className="title">Page not found!</h1>
            <p>Sorry, but the page you were looking for could not be found.</p>
            <div className="back-totop-button cerchio d-inline-block">
              <Link
                className="axil-button button-rounded hover-flip-item-wrapper"
              to={'/'}
              >
                <span className="hover-flip-item">
                  <span data-text="Back to Homepage">Back to Homepage</span>
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  )
}

export default Eroor