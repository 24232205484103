/* eslint-disable */

import React, { useEffect, useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Rating } from 'react-simple-star-rating'


// import Commntes from './Commntes'
import Comments from "./Commntes";
import axios from "axios";
import { Baseurl } from "./Baseurl";
import CommentsSection from "./CommentsSection";
import { useNavigate } from "react-router-dom";

function Detailpost({ id }) {
  const [rating, setRating] = useState(0)
// console.log(rating,"ratting");
  const handleRating = (rate) => {
    setRating(rate)
  }
  const navigate = useNavigate()
  const [data, setData] = useState([]);
  const [comments, setComments] = useState([]);
  console.log(comments);

  const getSinglePost = async () => {
    try {
      const response = await axios.get(
        `${Baseurl}/api/post/${id}`
      );
      setData(response?.data?.post);
    } catch (error) {
      console.error("Error fetching post:", error);
    }

  };
  const HandleClick = (e) => {
    axios.get(`${Baseurl}/api/post/like/${id}`).then((res)=>{
      console.log(res);
    })
    e.preventDefault();
    getSinglePost()

    // Setisclicked(isclicked === id ? null : id);
    console.log(id);
    // console.log(SearchParam);
    // axios.get("https://dontmess.devssh.xyz/api/post/like/")
  }
  const getComments = async () => {
    try {
      const response = await axios.get(
        `${Baseurl}/api/comment?post_id=${id}`
      );
      setComments(response.data.comments);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  useEffect(() => {
    getSinglePost();
    getComments();
    window.scrollTo(0, 0);
  }, [id]);

  return (
    <>
    <div className="post-single-wrapper axil-section-gap bg-color-white">
      <span style={{position:"relative",left:"10%",fontSize:"20px" ,cursor:"pointer"}} onClick={()=>navigate('/')}>

    <IoMdArrowRoundBack  className="mb-1 mr-2" />
    Go BAck
      </span>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="axil-post-details">
              <p className="has-medium-font-size text-center" style={{fontSize:"30px", fontWeight:"700"}}>{data?.title}</p>
              <figure className="wp-block-image">
              {data?.files && data.files.length > 0 && (
// <<<<<<< HEAD
// <<<<<<< HEAD
  <img src={Baseurl + data.files[0].name} alt="Post Images" style={{width:"100%",height:"50%",}} />
// =======
  // <img src={Baseurl + data.files[0].name} alt="Post Images" style={{width:"100%"}} />
// >>>>>>> e273e2f0671228b49ee4a86798e9de24fc2228af
// =======
  // <img src={Baseurl + data.files[0].name} alt="Post Images" style={{width:"100%"}} />
)}

              </figure>
              {/* <p>{data?.description}</p> */}
              <div dangerouslySetInnerHTML={{ __html: data?.description }} />
              
              
              {/* <p>
              “Every year, app and game developers demonstrate exceptional
              craftsmanship and we’re honoring the best of the best,” said Ron
              Okamoto, Apple’s vice president of Worldwide Developer Relations.
              “Receiving an Apple Design Award is a special and laudable
              accomplishment. Past honorees have made some of the most noteworthy
              apps and games of all time. Through their vision, determination, and
              exacting standards, the winning developers inspire not only their
              peers in the Apple developer community, but all of us at Apple,
              too.”
            </p>
            <h2>Apple Design Award Winners: Apps</h2>
            <p>
              {" "}
              Apple today named eight app and game developers receiving an Apple
              Design Award, each one selected for being thoughtful and creative.
              Apple Design Award winners bring distinctive new ideas to life and
              demonstrate deep mastery of Apple technology. The apps spring up
              from developers large and small, in every part of the world, and
              provide users with new ways of working, creating, and playing.
            </p>
            <p>
              “Every year, app and game developers demonstrate exceptional
              craftsmanship and we’re honoring the best of the best,” said Ron
              Okamoto, Apple’s vice president of Worldwide Developer Relations.
              “Receiving an Apple Design Award is a special and laudable
              accomplishment. Past honorees have made some of the most noteworthy
              apps and games of all time. Through their vision, determination, and
              exacting standards, the winning developers inspire not only their
              peers in the Apple developer community, but all of us at Apple,
              too.”
            </p>
            <blockquote>
              <p>
                “Most of us felt like we could trust each other to be quarantined
                together, so we didn’t need to wear masks or stay far apart.”
              </p>
            </blockquote> */}
              {/* <figure className="wp-block-image">
              <img
                src="/assets/images/post-single/post-single-03.jpg"
                alt="Post Images"
              />
              <figcaption>
                The Apple Design Award trophy, created by the Apple Design team,
                is a symbol of achievement and excellence.
              </figcaption>
            </figure>
            <h2>Apple Design Award Winners: Apps</h2>
            <p>
              <a href="f" onClick={(e)=>e.preventDefault()} alt="data">Apple today named</a> eight app and game developers
              receiving an Apple Design Award, each one selected for being
              thoughtful and creative. Apple Design Award winners bring
              distinctive new ideas to life and demonstrate deep mastery of Apple
              technology. The apps spring up from developers large and small, in
              every part of the world, and provide users with new ways of working,
              creating, and playing.
            </p>
            <p>
              “Every year, app and game developers demonstrate exceptional
              craftsmanship and we’re honoring the best of the best,” said Ron
              Okamoto, Apple’s vice president of Worldwide Developer Relations.
              “Receiving an Apple Design Award is a special and laudable
              accomplishment. Past honorees have made some of the most noteworthy
              apps and games of all time. Through their vision, determination, and
              exacting standards, the winning developers inspire not only their
              peers in the Apple developer community, but all of us at Apple,
              too.”
            </p>
            <figure className="wp-block-image">
              <img
                src="/assets/images/post-single/post-single-04.jpg"
                alt="Post Images"
              />
              <figcaption>
                The Apple Design Award trophy, created by the Apple Design team,
                is a symbol of achievement and excellence.
              </figcaption>
            </figure>
            <h3>Apple Design Award Winners: Apps </h3>
            <p>
              {" "}
              <a href="f" onClick={(e)=>e.preventDefault()} alt="data">Apple today named</a> eight app and game developers
              receiving an Apple Design Award, each one selected for being
              thoughtful and creative. Apple Design Award winners bring
              distinctive new ideas to life and demonstrate deep mastery of Apple
              technology. The apps spring up from developers large and small, in
              every part of the world, and provide users with new ways of working,
              creating, and playing.
            </p>
            <p>
              “Every year, app and game developers demonstrate exceptional
              craftsmanship and we’re honoring the best of the best,” said Ron
              Okamoto, Apple’s vice president of Worldwide Developer Relations.
              “Receiving an Apple Design Award is a special and laudable
              accomplishment. Past honorees have made some of the most noteworthy
              apps and games of all time. Through their vision, determination, and
              exacting standards, the winning developers inspire not only their
              peers in the Apple developer community, but all of us at Apple,
              too.”{" "}
            </p>
            <p>
              More than 250 developers have been recognized with Apple Design
              Awards over the past 20 years. The recognition has proven to be an
              accelerant for developers who are pioneering innovative designs
              within their individual apps and influencing entire categories.
              Previous winners such as Pixelmator, djay, Complete Anatomy,
              HomeCourt, “Florence,” and “Crossy Road” have set the standard in
              areas such as storytelling, interface design, and use of Apple tools
              and technologies.
            </p>
            <p>
              For more information on the apps and games, visit the{" "}
              <a href="f" onClick={(e)=>e.preventDefault()} alt="data">App Store</a>.
            </p> */}
              {/* <div className="tagcloud">
              <a href="f" onClick={(e)=>e.preventDefault()} alt="data">Design</a>
              <a href="f" onClick={(e)=>e.preventDefault()} alt="data">Life Style</a>
              <a href="f" onClick={(e)=>e.preventDefault()} alt="data">Web Design</a>
              <a href="f" onClick={(e)=>e.preventDefault()} alt="data">Development</a>
              <a href="f" onClick={(e)=>e.preventDefault()} alt="data">Design</a>
              <a href="f" onClick={(e)=>e.preventDefault()} alt="data">Life Style</a>
            </div> */}
              <div className="social-share-block">
              <div className="post-like" onClick={(e)=>HandleClick(e)} >
              <a href="f" alt="data">
                  {data?.liked_check === true ? (
                    <i className={`fal fa-thumbs-up i click `}  style={{color:"white"}} />
                      ) : (
                        <i className={`fal fa-thumbs-up i `}  />
                    )}
                    <span className="new1">{data.liked} Like</span>

                  </a>
                  
                </div>
               
                <ul className="social-icon icon-rounded-transparent md-size">
                  <li>
                    <a href="f" onClick={(e)=>e.preventDefault()} alt="data">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="f" onClick={(e)=>e.preventDefault()} alt="data">
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="f" onClick={(e)=>e.preventDefault()} alt="data">
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="f" onClick={(e)=>e.preventDefault()} alt="data">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                </ul>
              </div>
              {/* Start Author  */}
              {/* {comments.map((e, i) => {
                return (
                  <div className="about-author">
                    <div className="media">
                      <div className="thumbnail">
                        <a href="f" onClick={(e)=>e.preventDefault()} alt="data">
                          <img className="comment-image"
                            src={e?.user_image? Baseurl + e?.user_image :"/assets/images/post-images/author/author-b1.png"}
                            alt="Author Images"
                          />
                        </a>
                      </div>
                      <div className="media-body">
                        <div className="author-info">
                          <h5 className="title">
                            <a
                              className="hover-flip-item-wrapper"
                              href="f" onClick={(e)=>e.preventDefault()}
                              alt="data"
                            >
                              <span className="hover-flip-item">
                                <span data-text={e?.username}>
                                  {e?.username}
                                </span>
                              </span>
                            </a>
                          </h5>
                          <span className="b3 subtitle">Sr. UX Designer</span>
                        </div>
                        <div className="content">
                          <p className="b1 description">
                         {e?.comment}
                          </p>
                          <ul className="social-share-transparent size-md">
                            <li>
                              <a href="f" onClick={(e)=>e.preventDefault()} alt="data">
                                <i className="fab fa-facebook-f" />
                              </a>
                            </li>
                            <li>
                              <a href="f" onClick={(e)=>e.preventDefault()} alt="data">
                                <i className="fab fa-instagram" />
                              </a>
                            </li>
                            <li>
                              <a href="f" onClick={(e)=>e.preventDefault()} alt="data">
                                <i className="fab fa-twitter" />
                              </a>
                            </li>
                            <li>
                              <a href="f" onClick={(e)=>e.preventDefault()} alt="data">
                                <i className="far fa-envelope" />
                              </a>
                            </li>
                            <li>
                              <a href="f" onClick={(e)=>e.preventDefault()} alt="data">
                                <i className="fab fa-linkedin-in" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })} */}

              {/* End Author  */}
              {/* Start Comment Form Area  */}
              {/* <Rating onClick={handleRating} initialValue={rating}   /> */}

              <Comments id={id} comments={comments} getComments={getComments}/>
              <CommentsSection comments={comments}/>
              {/* End Comment Form Area  */}
            </div>
          </div>
        </div>
      </div>
    </div>
    </>

  );
}

export default Detailpost;