/* eslint-disable */
import React from "react";
import { Baseurl } from "./Baseurl";
import { WhatsappShareButton } from "react-share";

function CommentsSection({ comments }) {
  const url = "https://dontmesswiththedon.dev-bt.xyz/";
  const quote = "Check out this amazing Blogs!";

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };

  const formatTimestamp1 = (timestamp) => {
    const date1 = new Date(timestamp);
    return date1.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
  };

  return (
    <>
      <h4 className="title mt-5 fs-12">
        {comments.length === 0 ? "No" : comments.length} comments
      </h4>
      {comments.map((e, i) => {
        return (
          <div className="axil-comment-area mt-5 col-lg-6  shadow_1" style={{borderRadius: "55px 55px 0px 55px"}} >
            <ul className="comment-list px-5" style={{backgroundColor:"#D3D3D3",borderRadius: "30px 30px 0px 30px" }}>
              <li className="comment">
                <div className="comment-body">
                  <div className="single-comment">
                    <div className="comment-inner">
                      <h6 className="commenter">
                        <a
                          className="hover-flip-item-wrapper"
                          href="hello"
                          onClick={(e) => e.preventDefault()}
                        >
                          <span className="hover-flip-item">
                            <span data-text={e?.username} style={{fontSize:"20px"}}>{e?.username}</span>
                          </span>
                        </a>
                      </h6>
                      <div className="comment-meta">
                        <div className="time-spent">
                          {formatTimestamp(e?.created_at)} at{" "}
                          {formatTimestamp1(e?.created_at)}
                        </div>
                      </div>
                      <div className="comment-text">
                        {/* <img
                          src={
                            e?.user_image
                              ? Baseurl + e?.user_image
                              : "/assets/images/post-images/author/author-b1.png"
                          }
                          alt="Author Images"
                        /> */}
                        <p className="b2">
                          {e?.comment}
                        </p>
                        <img
                          style={{width:"100%"}}
                          src={Baseurl + e?.comment_image}
                          alt="Comment Image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        );
      })}
    </>
  );
}

export default CommentsSection;
