import React, { useEffect } from 'react'
import Header from '../Components/Header'
// import DetailHero from '../Components/DetailHero'
import Detailpost from '../Components/Detailpost'
import Footer from '../Components/Footer'
import { useParams } from 'react-router-dom'

function Details() {
  // console.log(id);
  useEffect(()=>{
    window.scrollTo(0,0)

  },[])
  const { id } = useParams()
  return (
   <>
   <Header/>
   {/* <DetailHero/> */}
   <Detailpost id={id}/>
   <Footer/>
   </>
  )
}

export default Details