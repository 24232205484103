import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Baseurl } from './Baseurl';

function AddPostButton() {
  const [dataone, setDataone] = useState([]);
  const getAllPostone = async () => {
    try {
      const response = await axios.get(
        `${Baseurl}/api/button`
      );
      setDataone(response.data.buttons);
      // console.log(response.data.videos,"Faiz1");
      
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };
  
  useEffect(()=>{
    window.scrollTo(0,0)
    getAllPostone()
    
  },[])
  return (
    <div className="col-lg-12 mt-4" style={{display:"flex", justifyContent:"center"}}>
    <Link to={'/add-post'}>
    <div className="form-submit cerchio" style={{width:"100%"}}>
      <input
      style={{width:"300px"}}
      type="submit"
      id="submit"
      className="axil-button button-rounded"
      value={dataone[0]?.name}
      />
    </div>
      </Link>
  </div>
  )
}

export default AddPostButton