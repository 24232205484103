import axios from 'axios';
import React, {  useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { Baseurl } from './Baseurl';

function Comments({id,comments ,getComments}) {
  // console.log(comments.length);
  const validationSchema = Yup.object().shape({
    userName: Yup.string().required('User Name is required'),
    // uploadImage: Yup.mixed().required('Your Image is required'),
    // postImage: Yup.mixed().required('Comments Image is required'),
    postDescription: Yup.string().required('Post Description is required'),
  });

  const formik = useFormik({
    initialValues: {
      userName: '',
      uploadImage: null,
      postImage: null,
      postDescription: '',
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      console.log(values);
      try {
        const formData = new FormData();
        formData.append('post_id', id);
        formData.append('username', values.userName);
        // formData.append('user_image', values.uploadImage);
        formData.append('comment_image', values.postImage);
        formData.append('comment', values.postDescription);

        await axios.post(`${Baseurl}/api/comment`, formData);
        

        // setSubmitting(false);
        Swal.fire("Comment Add Succesfully")
        resetForm();
        getComments()
      } catch (error) {
        setSubmitting(false);
        console.error('Error adding post:', error);
        // toast.error('Failed to add post');
      }
    },
  });
 

  const [showComments, setShowComments] = useState(false);

  const toggleComments = () => {
    setShowComments(!showComments);
  };
 

  return (
    <div className="axil-comment-area">
      <div className="axil-total-comment-post">
        <div className="title">
          {/* <h4 className="mb--0"> {comments? comments.length +'+ Comments' : 'No Comments'}</h4> */}
        </div>
        <div className="add-comment-button cerchio">
          <button className="axil-button button-rounded" onClick={toggleComments} tabIndex={0}>
            <span>{'Add Your Comment'}</span>
          </button>
        </div>
      </div>

      {showComments && (
        <div data-aos="zoom-in-right">
          <div className="comment-respond">
            <h4 className="title" style={{fontSize:"26px"}}>Post a comment</h4>
            <form onSubmit={formik.handleSubmit}>
              <p className="comment-notes">
                <span id="email-notes"></span> Required fields are marked{' '}
                <span className="required">*</span>
              </p>
              <div className="row row--10">
                <div className="col-lg-6 col-md-4 col-12">
                  <div className="form-group">
                    <label>Your Name*</label>
                    <input
                      id="userName"
                      name="userName"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.userName}
                    />
                    {formik.touched.userName && formik.errors.userName ? (
                      <div className="error">{formik.errors.userName}</div>
                    ) : null}
                  </div>
                </div>
                {/* <div className="col-lg-4 col-md-4 col-12">
                  <div className="form-group">
                    <label>Your Image*</label>
                    <input
                      id="uploadImage"
                      name="uploadImage"
                      type="file"
                      style={{ paddingTop: '9px' }}
                      onChange={(event) => {
                        formik.setFieldValue('uploadImage', event.currentTarget.files[0]);
                      }}
                    />
                    {formik.touched.uploadImage && formik.errors.uploadImage ? (
                      <div className="error">{formik.errors.uploadImage}</div>
                    ) : null}
                  </div>
                </div> */}
                <div className="col-lg-6 col-md-4 col-12">
                  <div className="form-group">
                    <label>Comments Image</label>
                    <input
                      id="postImage"
                      name="postImage"
                      type="file"
                      style={{ paddingTop: '9px' }}
                      onChange={(event) => {
                        formik.setFieldValue('postImage', event.currentTarget.files[0]);
                      }}
                    />
                    {/* {formik.touched.postImage && formik.errors.postImage ? (
                      <div className="error">{formik.errors.postImage}</div>
                    ) : null} */}
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label>Leave a Comment*</label>
                    <textarea
                      name="postDescription"
                      id="postDescription"
                      defaultValue={''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.postDescription}
                    />
                    {formik.touched.postDescription && formik.errors.postDescription ? (
                      <div className="error">{formik.errors.postDescription}</div>
                    ) : null}
                  </div>
                </div>
             
                <div className="col-lg-12">
                  <div className="form-submit cerchio">
                    <input
                      name="submit"
                      type="submit"
                      id="submit"
                      className="axil-button button-rounded"
                      defaultValue="Post Comment"
                      disabled={formik.isSubmitting}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default Comments;
