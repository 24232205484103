import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import HeroSection from "../Components/HeroSection";
import PostArea from "../Components/PostArea";
import Footer from "../Components/Footer";
import AddPostButton from "../Components/AddPostButton";
import axios from "axios";
import { Baseurl } from "../Components/Baseurl";

function Home() {
  const [data, setData] = useState([]);
  // const [showFullContent, setShowFullContent] = useState(false);

  const getAllPost = async () => {
    try {
      const response = await axios.get(`${Baseurl}/api/videotitle/get`);
      setData(response.data.video_title);
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllPost();
  }, []);

  // const handleReadMore = () => {
  //   setShowFullContent(true);
  // };

  return (
    <>
      <Header />
      <HeroSection />
      <div className="px-5 py-5 container"
        style={{ fontSize: "16px", lineHeight: "17px", textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: data?.title }} />
      {/* <p
        className="px-5 py-5 "
        style={{ fontSize: "16px", lineHeight: "17px", textAlign: "justify" }}
      >
        {showFullContent ? data?.title : data?.title?.slice(0, 600)}
        {!showFullContent && data?.title?.length > 200 && (
          <span onClick={handleReadMore} style={{ cursor: "pointer", color: "blue" }}>
            {" "}
            Read More
          </span>
        )}
      </p> */}
      <AddPostButton />
      <PostArea />
      <Footer />
    </>
  );
}

export default Home;
