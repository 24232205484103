/* eslint-disable */

import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Baseurl } from "./Baseurl";
import { CiCircleChevLeft, CiCircleChevRight } from "react-icons/ci";
import { useLocation, useSearchParams } from "react-router-dom";
// import image from 'public/assets/images/image.jpeg'
import image from '../image/image.jpeg'
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

function PostArea() {
  const url = "https://dontmesswiththedon.dev-bt.xyz/?id=home";
  const quote = "Check out this amazing Blogs!";
  const [data, setData] = useState([]);
  const [activeButton, setactiveButton] = useState("recent");
  const [currentPage, setCurrentPage] = useState(1);
  const [copiedLinkId, setCopiedLinkId] = useState(null);
  const [postsPerPage] = useState(5);
  const [isclicked, Setisclicked] = useState(null);
  // const [activeButton] = useState(5);

  const Navigate = useNavigate();
  const { pathname } = useLocation();
  const [SearchParams] = useSearchParams();
  const SearchParam = SearchParams.get("id");
  const ref = useRef(null);
  const HandleClick = (e, id) => {
    axios.get(`${Baseurl}/api/post/like/${id}`).then((res) => {
      console.log(res);
    });
    e.preventDefault();
    getAllPost();
    Setisclicked(isclicked === id ? null : id);
    console.log(id);
    // console.log(SearchParam);
    // axios.get("https://dontmess.devssh.xyz/api/post/like/")
  };

  useEffect(() => {
    if (SearchParam) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [SearchParam]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllPost();
  }, [currentPage]);

  const getAllPost = async () => {
    try {
      const response = await axios.get(
        `${Baseurl}/api/post?page=${currentPage}`
      );
      setData(response.data.posts);
      console.log(response.data.posts,"Faiz1");

    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };

  const formatTimestamp1 = (timestamp) => {
    const date1 = new Date(timestamp);
    return date1.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
  };
  const Popular = async() => {
    try {
      const response = await axios.get(
        `${Baseurl}/api/post?popular=yes`
      );
console.log(response.data.posts,"Faiz");
      setData(response?.data?.posts);
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };
  const copyLink = (e, id) => {
    e.preventDefault();
    setCopiedLinkId(id);
    navigator.clipboard.writeText(url);
    setTimeout(() => {
      setCopiedLinkId(null);
    }, 2000);
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(data.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  const [dataone, setDataone] = useState([]);
  const getAllPostone = async () => {
    try {
      const response = await axios.get(
        `${Baseurl}/api/button`
      );
      setDataone(response.data.buttons);
      // console.log(response.data.videos,"Faiz1");
      
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };
  
  useEffect(()=>{
    window.scrollTo(0,0)
    getAllPostone()
    
  },[])

  return (
    <>
      <div className="simple-ui d-flex justify-content-center mt-5">
        <ul className="nav nav-tabs activetabs">
          <li className="nav-item" onClick={()=>getAllPost()}>
            <button 
            
              className={`nav-link ${
                activeButton == dataone[1]?.name ? "active" : ""
              }`}
              onClick={() => setactiveButton(dataone[1]?.name)}
              style={
                activeButton == dataone[1]?.name
                  ? {
                      background: "red",
                      color: "white",
                      borderBottom: "2px solid red",
                      border: "none",
                    }
                  : {
                      background: "white",
                      color: "red",
                      borderBottom: "2px solid red",
                      border: "none",
                    }
              }
            >
              {dataone[1]?.name}
            </button>
          </li>
          <li className="nav-item" onClick={Popular}>
            <button
              className={`nav-link ${
                activeButton == dataone[2]?.name ? "active" : ""
              }`}
              onClick={() => setactiveButton(dataone[2]?.name)}
              style={
                activeButton == dataone[2]?.name
                  ? {
                      background: "red",
                      color: "white",
                      borderBottom: "2px solid red",
                      border: "none",
                    }
                  : {
                      background: "white",
                      color: "red",
                      borderBottom: "2px solid red",
                      border: "none",
                    }
              }
            >
              {dataone[2]?.name}
            </button>
          </li>
        </ul>
      </div>

      {currentPosts?.map((item, index) => (
        <div
          style={{
            boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.2)",
            padding: "0px",
          }}
          ref={ref}
          key={index}
          className="content-block post-list-view axil-control mt--30 container"
        >
          <div
            className="post-thumbnail"
            style={{}}
            onClick={() => Navigate(`/post-detail/${item.id}`)}
          >
            {item?.files?.length === 0 ?(
              <img
                src={image}
                alt="Post hi"
                className=""
              />
            ) : (
              <img
                src={Baseurl + item?.files[0].name}
                alt="Post Images"
                className=""
              />
            ) }
          </div>

          <div className="post-content">
            {/* <div class="ratings ld-none">
            <div class="empty-stars"></div>
            <div className="full-stars" style={{ width: "50%" }}></div>
          </div> */}
            <div className="social-share-block ratings">
              <div className="post-like">
                <a href="f" onClick={(e) => HandleClick(e, item.id)} alt="data">
                  {item?.liked_check === true ? (
                    <i className={`fal fa-thumbs-up i click `} />
                  ) : (
                    <i className={`fal fa-thumbs-up i `} />
                  )}
                </a>
                <span className="new1">{item.liked} Like</span>
              </div>
            </div>
            <div className="post-cat">
              <div className="post-cat-list">
                <a
                  className="hover-flip-item-wrapper"
                  href="#"
                  onClick={(e) => e.preventDefault()}
                  alt="data"
                >
                  <span className="hover-flip-item">
                    <span data-text={item.title} style={{ fontSize: "20px" }}>
                      {item.title}
                    </span>
                  </span>
                </a>
              </div>
            </div>
            <h2 className="title">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  Navigate(`/post-detail/${item.id}`);
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.description.slice(0, 150) + "...",
                  }}
                  className="title"
                />
              </a>
            </h2>

            <div className="post-meta-wrapper">
              <div
                className="post-meta"
                onClick={() => Navigate(`/post-detail/${item.id}`)}
              >
                <div className="content">
                  <h6 className="post-author-name">
                    <span
                      className="hover-flip-item "
                      style={{ marginBottom: "20px" }}
                    >
                      <span
                        data-text={item.username}
                        style={{ fontSize: "16px" }}
                      >
                        {item.username}
                      </span>
                    </span>
                  </h6>
                  <ul className="post-meta-list">
                    <li>{formatTimestamp(item.created_at)}</li>
                    <li> {formatTimestamp1(item.created_at)}</li>
                  </ul>
                </div>
              </div>
              <ul className="social-share-transparent justify-content-end">
                <li>
                  <FacebookShareButton url={url} quote={quote}>
                    <a href="#" onClick={(e) => e.preventDefault()} alt="data">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </FacebookShareButton>
                </li>
                <li>
                  <WhatsappShareButton url={url} quote={quote}>
                    <a href="#" onClick={(e) => e.preventDefault()} alt="data">
                      <i className="fab fa-whatsapp" />
                    </a>
                  </WhatsappShareButton>
                </li>
                <li>
                  <TwitterShareButton url={url} quote={quote}>
                    <a href="#" onClick={(e) => e.preventDefault()} alt="data">
                      <i className="fab fa-twitter" />
                    </a>
                  </TwitterShareButton>
                </li>
                <li className="" style={{ marginTop: "6px" }}>
                  <a href="#" onClick={(e) => copyLink(e, item.id)} alt="data">
                    {copiedLinkId === item.id ? (
                      <i className="fas fa-check" />
                    ) : (
                      <i className="fas fa-link" />
                    )}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ))}

      {/* Pagination */}
      <div className="pagination">
        <button
          className="pagination-button"
          onClick={() => setCurrentPage((prev) => prev - 1)}
          disabled={currentPage === 1}
        >
          <CiCircleChevLeft style={{ fontSize: "24px" }} />
        </button>
        {pageNumbers.map((number) => (
          <button
            key={number}
            className={`pagination-button ${
              currentPage === number ? "active" : ""
            }`}
            onClick={() => setCurrentPage(number)}
          >
            {number}
          </button>
        ))}
        <button
          className="pagination-button"
          onClick={() => setCurrentPage((prev) => prev + 1)}
          disabled={currentPage === Math.ceil(data.length / postsPerPage)}
        >
          <CiCircleChevRight style={{ fontSize: "24px" }} />
        </button>
      </div>
    </>
  );
}

export default PostArea;
